import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import {productsReducer} from "./reducer/procutsReducer";
import {thunk} from "redux-thunk";


export const store = createStore(
  combineReducers({
    products: productsReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk)),
);
