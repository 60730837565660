import axios from "axios";

export const getProducts = () => {
  return dispatch => {
    axios('../../content.json')
      .then(({data}) => {
        dispatch({
          type: 'GET_PRODUCTS',
          payload: data
        })
      })
  }
}