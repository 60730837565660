import { createTheme } from '@mui/material';
import { styled } from '@mui/system';

const initialTheme = createTheme({
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: {
      main: '#FF9900',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#52176D',
    },
    default: {
      main: '#B9B9B9',
    },
    grey: {
      800: 'rgba(15, 100, 226, 0.08)',
    },
    light: {
      main: '#FFFFFF',
    },
    background: {
      default: '#FCFAF6',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
  },
  typography: {
    fontFamily: 'Montserrat !important',
  },
  breakpoints: {
    values: {
      zero: 0,
      xxs: 400,
      xs: 669,
      sm: 984,
      md: 1290,
      lg: 1550,
    },
  },
});

export const radiusOfComponents = '14px';

export const ContentWrapper = styled('div')({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

const theme = createTheme(initialTheme, {
  ...initialTheme,
  typography: {
    h1: {
      fontSize: '50px',
      fontWeight: 600,
      [initialTheme.breakpoints.down('lg')]: {
        fontSize: '42px',
      },
      [initialTheme.breakpoints.down('md')]: {
        fontSize: '36px',
      },
      [initialTheme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
      [initialTheme.breakpoints.down('xs')]: {
        fontSize: '24px',
      },
    },
    h2: {
      fontSize: '40px',
      lineHeight: '1.2',
      fontWeight: 600,
      [initialTheme.breakpoints.down('md')]: {
        fontSize: '26px',
      },
      [initialTheme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
      // [initialTheme.breakpoints.down('xs')]: {
      //   fontSize: '24px',
      // },
    },
    h3: {
      fontSize: '32px',
      lineHeight: '1.2',
      fontWeight: 600,
      [initialTheme.breakpoints.down('md')]: {
        fontSize: '22px',
      },
      [initialTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      // [initialTheme.breakpoints.down('xs')]: {
      //   fontSize: '24px',
      // },
    },
    h4: {
      fontSize: '30px',
      fontWeight: 600,
      lineHeight: '1.2',
      [initialTheme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
      [initialTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      // [initialTheme.breakpoints.down('xs')]: {
      //   fontSize: '24px',
      // },
    },
    h5: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '1.2',
      [initialTheme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
      [initialTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      // [initialTheme.breakpoints.down('xs')]: {
      //   fontSize: '24px',
      // },
    },
    body1: {
      fontSize: '26px',
      [initialTheme.breakpoints.down('lg')]: {
        fontSize: '22px',
      },
      [initialTheme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
    body2: {
      fontSize: '20px',
      [initialTheme.breakpoints.down('md')]: {
        fontSize: '15px',
      },
      [initialTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
  },
});

export default theme;
