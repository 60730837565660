import React from 'react';
import {Box} from "@mui/material";

const ImageGallery = ({assets}) => {

  return (
    <Box sx={{
      display: 'flex',
      margin: '0 -10px',
      flexWrap: 'wrap',
      padding: 'unset!important',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      }
    }}
    >
      <Box sx={{width: '66.66%', padding: '10px'}}>
        <img src={assets[0]} alt=""/>
      </Box>
      <Box sx={{width: '33.33%', padding: '10px'}}>
        <img src={[assets[1]]} alt=""/>
      </Box>
      <Box sx={{width: '33.33%', padding: '10px'}}>
        <img src={assets[2]} alt=""/>
      </Box>
      <Box sx={{width: '33.33%', padding: '10px'}}>
        <img src={assets[3]} alt=""/>
      </Box>
      <Box sx={{width: '33.33%', padding: '10px'}}>
        <img src={assets[4]} alt=""/>
      </Box>
    </Box>
  );
};

export default ImageGallery;