import React from 'react';
import {Box, Typography} from "@mui/material";
import ImageGallery from "../../Components/ImageGallery";
import ImageBuild1 from "../../Components/images/image-016.png";
import ImageBuild2 from "../../Components/images/image-017.png";
import ImageBuild3 from "../../Components/images/image-018.png";
import ImageBuild4 from "../../Components/images/image-019.png";
import ImageBuild5 from "../../Components/images/image-020.png";
import ImgFactory1 from "../../Components/images/image-012.png";
import ImgGallery1 from "../../Components/images/imgGallery1.jpg";
import ImgGallery4 from "../../Components/images/imgGallery4.jpg";
import ImgFactory2 from "../../Components/images/image1.png";
import ImgFactory3 from "../../Components/images/image-014.png";
import Layout from "../../Components/Layout";

const AboutPage = () => {
  return (
    <Layout>
    <Box className={'container'}>
      <Box>
        <Typography sx={subTitleSx} variant={'h2'}>
          Jiangxi Zhongke Baishi Pharmaceutical Co., Ltd
        </Typography>
      </Box>
      <Box sx={thirdBlockSx}>
        <ImageGallery assets={secondImgGallery}/>
        <Typography>
          Общая площадь 108 акров, стандартизированное здание площадью более 70 000 квадратных метров. Имеет более
          20 000 международных стандартов стерильной очистки производственных цехов, первоклассное оборудование для
          обеспечения безопасности производства продукции. Мы фокусируемся в основном на оздоровительных
          биофармацевтических и медицинско- косметических отраслях, включая косметическую, потребительскую продукции
          и медицинское оборудование, чтобы предоставить владельцам брендов всеобъемлющую систему цепочки поставок
          Основная продукция: потребительские товары, гинекологические средства, зубная паста, средства для глаз,
          сертифицированная косметика
        </Typography>
      </Box>
      <Box sx={[imgWrapper, {mt: '15px'}]}>
        <Box sx={imgItem}>
          <Box>
            <img src={ImageBuild1} alt=""/>
            <Typography variant={'h4'}>
              Guangzhou Zhongke Baishi Health Industry Co., Ltd
            </Typography>
            <Typography>
              Площадь здания составляет более 20 000 квадратных метров, а площадь очистного цеха - более 10 000
              квадратных метров.
              Основное производство: линия по производству косметики, зубной пасты, средства для обработки ротовой
              полости с ежедневной производительностью 200 000 тюбиков.
              Зона экономического развития Гуандун Конхуа, промышленный парк высоких технологий, район Цунхуа, город
              Гуанчжоу
            </Typography>
          </Box>
        </Box>
        <Box sx={imgItem}>
          <Box>
            <img src={ImageBuild2} alt=""/>
            <Typography variant={'h4'}>
              Guangdong Dezhou Medical Equipment Co., Ltd
            </Typography>
            <Typography>
              Цех очистки площпдью более 10 000 квадратных метров и с наличием более 100 допусков второго класса.
              Основное производство: медицинские изделия класса II привезенные из Цзилиня и Хунана в Гуандун для производства.
              Промышленный парк Синьцзяна, улица Дунчэн, город Сихуэй
            </Typography>
          </Box>
        </Box>
        <Box sx={imgItem}>
          <Box>
            <img src={ImageBuild3} alt=""/>
            <Typography variant={'h4'}>Shanxi Kangbijian Pharmaceutical Group Co., Ltd</Typography>
            <Typography>Площадь завода 11380 квадратных метров.

              Основная продукция: медицинские изделия I и II классов, обезболивающие, пластыри, зубная паста.

              Зона экономического развития Хоума, дорога Ванван, промышленный парк Дуаньмао</Typography>
          </Box>
        </Box>
        <Box sx={imgItem}>
          <Box>
            <img src={ImageBuild4} alt=""/>
            <Typography variant={'h4'}>Qinghai Kangliya Medical Equipment Co., Ltd</Typography>
            <Typography>Цех очистки с площадью более чем 10 000 квадратных метров и более 300 наименований продукции

              Основная продукция: Медицинские изделия I класса, Обезболивающие для глаз, кожи, кишечника и прямой кишки

              Зона экономического и технологического развития Синина, Парк предпринимательства на улице Минхэ
            </Typography>
          </Box>
        </Box>
        <Box sx={imgItem}>
          <Box>
            <img src={ImageBuild5} alt=""/>
            <Typography variant={'h4'}>Guangdong Teyi Health Industry Co., Ltd</Typography>
            <Typography>Цех очистки площадью более 10 000 квадратных метров

              Основное производство: порошки, таблетки, пилюли, мази, пероральная жидкость

              Промышленный парк Хунъюй Чжихуэй в районе Хуаду города Гуанчжоу</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    </Layout>
  );
};

export default AboutPage;

const subTitleSx = theme => ({
  fontWeight: 600,
  textAlign: 'center',
  mt: '45px',
  mb: '25px',
  [theme.breakpoints.down('md')]: {
    mt: '30px',
    mb: '18px'
  }
})

const imgWrapper = theme => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -15px'
})

const imgItem = theme => ({
  width: '33.33%',
  padding: '15px',
  '& div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  '& img': {
    width: '100%',
    objectFit: 'cover'
  },
  [theme.breakpoints.down('md')]: {
    width: '50%'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '5px',
    paddingBottom: '25px',
    width: '100%'
  }
})

const secondImgGallery = [
  ImgFactory1, ImgGallery1, ImgGallery4, ImgFactory2, ImgFactory3
]

const secondBlockSx = theme => ({
  marginTop: '25px',
  display: 'flex',
  gap: '20px',
  alignItems: 'flex-start',
  [theme.breakpoints.down(800)]: {
    flexDirection: 'column-reverse',
    '& div': {
      padding: '5px'
    }
  }
})

const thirdBlockSx = theme => ({
  [theme.breakpoints.down(800)]: {
    flexDirection: 'column',
    '& div': {
      padding: '5px'
    }
  }
})