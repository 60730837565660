import React from 'react';
import {Box, Typography} from "@mui/material";
import Layout from "../../Components/Layout";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ProductLIst from "../../Components/ProductLIst";

const CatalogPage = () => {
  const data = useSelector(state => state.products.products);

  return (
    <Layout>
    <Box className={'container'} sx={{paddingY: '25px'}}>
      <Typography variant={'h1'}>Наша продукция</Typography>
      <ProductLIst data={data} />
    </Box>
    </Layout>
  );
};

export default CatalogPage;

