import React from 'react';
import {Box, Typography} from "@mui/material";
import Logo from './images/huage-logo.png';
import {Link} from "react-router-dom";
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
  return (
    <footer>
      <Box sx={{backgroundColor: '#ed6c02', paddingY: '20px'}}>
        <Box
          className={'container'}
          sx={{
          color: '#fff',
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
        }}
        >
          <Link to={'/'}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
              <img style={{width: '60px'}} src={Logo} alt=""/>
              <Typography sx={{fontSize: '32px!important'}}>Хуагэ</Typography>
            </Box>
          </Link>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
            <Box sx={{display: "flex", alignItems: 'center', gap: '15px'}}>
              <PlaceIcon /> <Typography>
              Проспект Жибек-Жолу, 122
            </Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: 'center', gap: '15px'}}>
              <LocalPhoneIcon /> <Typography>
              +996 508 05 05 50
            </Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: 'center', gap: '15px'}}>
              <EmailIcon /> <Typography>
              <a href={'mailto:huage.kg@gmail.com'}>
                huage.kg@gmail.com
              </a>
            </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;