import React from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import Layout from "../../Components/Layout";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import MobileView from "./MobileView";
import WebView from "./WebView";

const ProductPage = () => {
  const {id} = useParams();
  const product = useSelector(state => state.products.products.find(item => item.id === +id));
  const xsMatch = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <Layout>
      <Box className={'container'} sx={[{paddingY: '25px'}, !xsMatch ? {maxWidth: '1250px'} : {}]}>
        {product && (xsMatch ?
          <MobileView product={product}/>
          :
          <WebView product={product}/>)
        }
      </Box>
    </Layout>
  );
};

export default ProductPage;