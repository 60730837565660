import React from 'react';
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const ProductLIst = ({data}) => {
  return (
    <Box sx={rowSx}>
      {
        data.map((item, index) => {
          return (
            <Box key={index} sx={colSx}>
              <Link to={`/product/${item.id}`}>
                <img src={item.mainImg} alt=""/>
                <Typography variant={'h5'}>{item.product}</Typography>
              </Link>
              {/*<p>{item.description}</p>*/}
            </Box>
          )
        })
      }
    </Box>
  );
};

export default ProductLIst;

const rowSx = theme => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -15px',
  marginTop: '15px',
})

const colSx = theme => ({
  width: '33.333%',
  padding: '15px',
  '& h5': {
    color: '#000'
  },
  '& img': {
    width: '100%',
    objectFit: 'cover',
    height: '480px'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': {
      height: '280px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    '& img': {
      height: 'unset'
    }
  }
})