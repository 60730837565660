import React, {useEffect} from 'react';
import {Box, Button, Typography} from "@mui/material";
import ImgMain from '../../Components/images/image1.png';
import Img1 from '../../Components/images/img1.jpg';
import Img2 from '../../Components/images/img2.jpg';
import ImgFactory from '../../Components/images/imgFactory.jpg';
import Layout from "../../Components/Layout";
import {useDispatch, useSelector} from "react-redux";
import ImageGallery from "../../Components/ImageGallery";
import ImgGallery1 from "../../Components/images/imgGallery1.jpg";
import ImgGallery2 from "../../Components/images/imgGallery2.jpg";
import ImgGallery3 from "../../Components/images/imgGallery3.jpg";
import ImgGallery4 from "../../Components/images/imgGallery4.jpg";
import ImgGallery5 from "../../Components/images/imgGallery5.jpg";
import ImgFactory1 from "../../Components/images/image-012.png";
import ImgFactory2 from "../../Components/images/image1.png";
import ImgFactory3 from "../../Components/images/image-014.png";
import ImageBuild1 from '../../Components/images/image-016.png'
import ImageBuild2 from '../../Components/images/image-017.png'
import ImageBuild3 from '../../Components/images/image-018.png'
import ImageBuild4 from '../../Components/images/image-019.png'
import ImageBuild5 from '../../Components/images/image-020.png'
import ProductLIst from "../../Components/ProductLIst";
import {Link, useNavigate} from "react-router-dom";
import QrCode from "../../Components/images/qr.jpeg";
import {Pagination, Autoplay, Navigation} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/navigation';
import {isAndroid, isIOS} from "react-device-detect";



const HomePage = () => {
  const dispatch = useDispatch()
  const data = useSelector(s => s.products.products)
  const navigate = useNavigate()

  const urlRedirect = () => {
    if (isIOS){
      return 'https://m.jrhg.net'
    } else if (isAndroid){
      return 'https://down.jrhg.net'
    } else {
      return 'https://m.jrhg.net'
    }
  }

  const handleGoToSite = (url) => {
    window.open(url, '_blank');
  }


  return (
    <Layout>
      <Box className={'container'} sx={homePageSx}>
        <Box sx={{marginTop: '15px'}}>
          <Typography variant={'h1'} align={'center'}>ОсОО Биоинженерная компания «Цзюрань Хуагэ»
          </Typography>
          {/*<Typography sx={{fontWeight: 600}} variant={'h1'} align={'center'}>Наша продукция</Typography>*/}
          <Swiper
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            autoplay={{
              delay: 3500,
            }}
            navigation={true}
            // pagination={{
            //   clickable: true,
            // }}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            {data.map(product =>
              <SwiperSlide>
                <Box sx={swiperItemSx}>
                  <Box className={'swiper-img-wrapper'}>
                    <img src={product.swipeImg} alt="img3"/>
                  </Box>
                  <Box className={'swiper-title-wrapper'}>
                    <Typography variant={'h4'}>{product.product}</Typography>
                    <Typography>
                      {product.desc}
                    </Typography>
                    <Button variant={'contained'}
                    onClick={() => navigate(`/product/${product.id}`)}
                    >
                      Подробнее
                    </Button>
                  </Box>
                </Box>
              </SwiperSlide>
            )
            }
          </Swiper>
        </Box>
        <Box
          id={'about'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '15px',
            gap: '20px',
            alignItems: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <iframe
            width="600"
            height="315"
            src={'https://www.youtube.com/embed/2JGfMzg_QyY'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
          <iframe
            width="600"
            height="315"
            src={'https://www.youtube.com/embed/r-vWbRAxDC0'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Box>
        <Box sx={introContentSx}>
          <Box
            sx={subIntroSx}
          >
            <Typography variant={'h2'}>Jiangxi Zhongke Baishi Pharmaceutical Co., Ltd</Typography>
            <Typography>
              Основное производство: потребительские, гинекологические товары, зубная паста, средства для глаз, 39 видов
              косметики, прошедших нац. контроль и сертификаты
            </Typography>
            <Typography>
              Общая площадь 108 акров, сразу же было построено 70 000 квадратных метров стандартнизированного завода,
              имеет 100 000 международных стандартов стерильной очистки. Площадь производственного цеха более 20 000
              квадратных метров, первоклассное оборудование, чтобы обеспечить безопасность производства продукции.
              Основное производство сфокусированно на биохимических оздоравительных добавках, включающих в себя
              косметические бренды, потребительские товары, медицинское оборудование и так далее. Все для того, чтобы
              обеспечить непрерывную цепочку поставок.
            </Typography>
            <Typography>
              Имеется <b>39 сертификатов</b> национальной косметологической отрасли.

              Общая площадь <b>108 гектаров</b>.

              Иследовательская и производственная лаборотория
            </Typography>
          </Box>
          <Box sx={introGallerySx}>
            <img src={ImgFactory} alt=""/>
            <Box sx={introImageSx}>
              <Box>
                <img src={Img1} alt=""/>
              </Box>
              <Box>
                <img src={Img2} alt=""/>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={secondBlockSx}>
          <ImageGallery assets={firstImgGallery}/>
          <Box>
            <Typography>
              Преимущества нашего производства

              Высокая производительность шести международных интеллектуальных производственных предприятий

              Самостоятельно построенные международные, профессиональные и интеллектуальные инновационные заводы с
              первоклассным производственным оборудованием, центрами исследований и разработок продукции, а также
              центрами
              контроля качества.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '25px',
            flexWrap: 'wrap',
            flexDirection: 'column'
          }}
        >
          <Button
            color={'success'}
            sx={{textTransform: 'unset', fontSize: '22px'}}
            variant={'contained'}
            onClick={() => handleGoToSite(urlRedirect())}
          >
            Перейти на платформу
          </Button>
          <Box sx={{
            width: '290px',
            backgroundColor: '#ed6c02',
            height: '290px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
              width: '250px',
              objectFit: 'cover',
              // height: '480px'
            }
          }}>
            <img src={QrCode} alt=""/>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
// 
export default HomePage;

const firstImgGallery = [
  ImgGallery1, ImgGallery2, ImgGallery3, ImgGallery4, ImgGallery5
]

const swiperItemSx = theme => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -10px',
  '& p': {
    textAlign: 'left'
  },
  '& .swiper-img-wrapper': {
    width: '60%',
    padding: '10px',
    '& img': {
      width: '100%',
      objectFit: 'cover'
    }
  },
  '& .swiper-title-wrapper': {
    width: '40%',
    padding: '10px',
    '& p': {
      textAlign: 'left',
      margin: '20px 0'
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .swiper-img-wrapper': {
      width: '100%',
      padding: '15px 10',
    },
    '& .swiper-title-wrapper': {
      width: '100%',
      height: "100%",
      padding: '15px 10',
      '& p': {
        textAlign: 'left',
        margin: '10px 0'
      },
      '& button': {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 0,
        marginTop: 'auto',
        marginBottom: '0'
      }
    },
  }
})

const secondBlockSx = theme => ({
  marginTop: '25px',
  display: 'flex',
  gap: '20px',
  alignItems: 'flex-start',
  [theme.breakpoints.down(800)]: {
    flexDirection: 'column-reverse',
    '& div': {
      padding: '5px'
    }
  }
})

const thirdBlockSx = theme => ({
  [theme.breakpoints.down(800)]: {
    flexDirection: 'column',
    '& div': {
      padding: '5px'
    }
  }
})

const introGallerySx = theme => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
  }
})

const subIntroSx = theme => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '7px',
})

const titleSx = theme => ({
  fontWeight: 600,
  // fontSize: '30px'
})

const subTitleSx = theme => ({
  fontWeight: 600,
  textAlign: 'center',
  mt: '45px',
  mb: '25px',
  [theme.breakpoints.down('md')]: {
    mt: '30px',
    mb: '18px'
  }
})

const imgWrapper = theme => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -15px'
})

const imgItem = theme => ({
  width: '33.33%',
  padding: '15px',
  '& div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  '& img': {
    width: '100%',
    objectFit: 'cover'
  },
  [theme.breakpoints.down('md')]: {
    width: '50%'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '5px',
    paddingBottom: '25px',
    width: '100%'
  }
})

const introContentSx = theme => ({
  display: 'flex',
  gap: '15px',
  '& img': {
    width: '551px',
    // height: '100%',
    objectFit: 'contain'
  },
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '441px'
    },
    gap: '10px'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& img': {
      maxWidth: '551px',
      width: '100%'
    }
  }
})

const introImageSx = theme => ({
  display: 'flex',
  gap: '5px',
  '& img': {
    width: '273px',
    // height: '100px',
    objectFit: 'contain'
  },
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '218px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& img': {
      maxWidth: '273px',
      width: '100%'
    }
  },
  [theme.breakpoints.down(588)]: {
    // marginX: 'px',
    '& div': {
      width: '50%',
    }
  }
})

const homePageSx = theme => ({
  paddingY: '45px',
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
  '& h1': {
    marginBottom: '20px'
  },
  [theme.breakpoints.down('sm')]: {
    paddingY: '20px',
    gap: '25px',
    '& .swiper-button-prev, .swiper-button-next': {
      top: '40%'
    }
  },
})