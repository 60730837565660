import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import CatalogPage from "./Pages/CatalogPage/CatalogPage";
import ProductPage from "./Pages/ProductPage/ProductPage";
import {useDispatch} from "react-redux";
import {getProducts} from "./redux/action/productAction";
import AboutPage from "./Pages/AboutPage/AboutPage";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts())
  }, []);

  return (
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/catalog" element={<CatalogPage/>}/>
        <Route path="/product/:id" element={<ProductPage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
      </Routes>
  );
};

export default App;