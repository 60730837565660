import React, { useState } from 'react';
import {Box, Button, Drawer, IconButton, List, ListItem, ListItemText, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {Link, Link as RouterLink, useNavigate} from 'react-router-dom';
import Logo from "../images/huage-logo.png";
import QrCode from '../images/qr.jpeg'
import { isAndroid, isIOS } from 'react-device-detect';

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(isOpen);
  };

  const menuItems = [
    { text: 'Главная', link: '/', redirect: false },
    { text: 'Каталог', link: '/catalog', redirect: false },
    { text: 'О нас', link: '/about', redirect: false },
    // { text: 'Контакты', link: '#contact', redirect: false },
    { text: 'Сайт приложение', redirect: true}
  ];

  const urlRedirect = () => {
    if (isIOS){
      return 'https://m.jrhg.net'
    } else if (isAndroid){
      return 'https://down.jrhg.net'
    } else {
      return 'https://m.jrhg.net'
    }
  }

  const handleGoToSite = (url) => {
    window.open(url, '_blank');
  }

  return (
    <Box>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        sx={{
          '& .MuiPaper-root': { backgroundColor: '#ed6c02'}
        }}
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Link to={'/'}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <img style={{width: '45px'}} src={Logo} alt=""/>
              <Typography sx={{fontSize: '30px!important'}}>Хуагэ</Typography>
            </Box>
          </Link>
          <List>
            {menuItems.map((item, index) => (
              <ListItem button key={index} onClick={() => {
                if (item.redirect) {
                  handleGoToSite(urlRedirect())
                } else {
                  navigate(item.link)
                }
              }}>
                <ListItemText sx={{color: '#fff'}} primary={item.text} />
              </ListItem>
            ))}
            <Box sx={{
              '& img': {
                width: '247px',
                objectFit: 'cover',
                paddingX: '2.5px'
                // height: '480px'
              }
            }}>
              <img src={QrCode} alt=""/>
            </Box>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
