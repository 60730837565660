import React from 'react';
import {Box, Typography} from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const WebView = ({product}) => {
  return (
    <Box
      sx={wrapper}
    >
      <Typography variant={"h2"}>{product.product}</Typography>
      <Box sx={{display: 'flex', gap: '30px', justifyContent: 'space-between'}}>
        <img src={product.mainImg} alt={product.product} className="main-img"/>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          {!!product.benefits.length && (
            <Box>
              <Typography variant={"h3"}>Преимущества:</Typography>
              <Box>
                {product.benefits.map((benefit, index) => (
                  <Typography key={index}>{benefit}</Typography>
                ))}
              </Box>
            </Box>
          )}
          {!!product.properties.length &&
            <Box>
              <Typography variant={"h3"}>Свойства :</Typography>

              <Box>
                {product.properties.map((property, index) => (
                  <Typography key={index}>{property}</Typography>
                ))}
              </Box>
            </Box>
          }
          {product.img2 && <img src={product.img2} alt="img2"/>}

        </Box>
      </Box>
      <Box sx={{display: 'flex', gap: '25px', justifyContent: 'space-between'}}>
        {product.consequences_of_ignoring.length > 0 && (
          <Box>
            <Typography variant={"h3"}>Последствия игнорирования:</Typography>
            <Box>
              {product.consequences_of_ignoring.map((consequence, index) => (
                <Typography key={index}>{consequence}</Typography>
              ))}
            </Box>
          </Box>
        )}
        {product.functions.length > 0 && (
          <Box>
            <Typography variant={"h3"}>Функции:</Typography>
            <Box>
              {product.functions.map((func, index) => (
                <Typography key={index}>{func}</Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{display: 'flex', gap: '30px'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
          {product.functional_ingredients.length > 0 && (
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '25px'}}>
              <Typography variant={"h3"}>Функциональные ингредиенты:</Typography>
              {product.functional_ingredients.map((ingredient, index) => (
                <div key={index}>
                  <Typography variant={"h3"}>{ingredient.name}</Typography>
                  <Box>
                    {ingredient.description.map((desc, i) => (
                      <Typography key={i}>{desc}</Typography>
                    ))}
                  </Box>
                </div>
              ))}
            </Box>
          )}
        </Box>
        {/*{product.img5 && <img src={product.img5} alt="img5"/>}*/}
        {/*{product.img6 && <img src={product.img6} alt="img6"/>}*/}
      </Box>
      <Box sx={{display: 'flex', gap: '25px', justifyContent: 'space-between'}}>
        {product.uses.length > 0 && (
          <Box>
            <Typography variant={"h3"}>Использование:</Typography>
            <Box>
              {product.uses.map((use, index) => (
                <Typography key={index}>{use}</Typography>
              ))}
            </Box>
          </Box>
        )}
        {product.application_areas.length > 0 && (
          <Box>
            <Typography variant={"h3"}>Области применения:</Typography>
            <Box>
              {product.application_areas.map((area, index) => (
                <Typography key={index}>{area}</Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
        <Typography variant={"h3"}>Презентация:</Typography>
        <Typography><strong>Бренд:</strong> {product.presentation.brand}</Typography>
        <Typography><strong>Название:</strong> {product.presentation.name}</Typography>
        <Typography><strong>Спецификация:</strong> {product.presentation.specification}</Typography>
        <Typography><strong>Область применения:</strong> {product.presentation.application_area}</Typography>
      </Box>
      {product.additional_info.targeted_action && (
        <Box>
          <Typography variant={"h3"}>Целенаправленное действие:</Typography>
          <Box>
            {product.additional_info.targeted_action.map((action, index) => (
              <Typography key={index}>{action}</Typography>
            ))}
          </Box>
        </Box>
      )}

      {product.additional_info.application_method && (
        <Box>
          <Typography variant={"h3"}>Метод применения:</Typography>
          <p>{product.additional_info.application_method}</p>
        </Box>
      )}
      <Box sx={{
        '& img': {
          maxHeight: '500px',
          objectFit: 'contain'
        }
      }}>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {product.img3 &&
            <SwiperSlide>
              <img src={product.img3} alt="img3"/>
              </SwiperSlide>
              }
          {product.img4 &&
            <SwiperSlide>
            <img src={product.img4} alt="img4"/>
            </SwiperSlide>
          }
          {product.img5 &&
         <SwiperSlide>
            <img src={product.img5} alt="img5"/>
          </SwiperSlide>
          }
          {product.img6 &&
          <SwiperSlide>
             <img src={product.img6} alt="img6"/>
          </SwiperSlide>
          }
          {product.img7 &&
          <SwiperSlide>
             <img src={product.img7} alt="img7"/>
          </SwiperSlide>
          }
          {product.img8 &&
            <SwiperSlide>
           <img src={product.img8} alt="img8"/>
          </SwiperSlide>
          }
          {product.img9 &&
          <SwiperSlide>
             <img src={product.img9} alt="img9"/>
          </SwiperSlide>}
          {product.img10 &&
          <SwiperSlide>
             <img src={product.img10} alt="img9"/>
          </SwiperSlide>
          }
          {product.img11 &&
          <SwiperSlide>
             <img src={product.img11} alt="img9"/>
          </SwiperSlide>
          }
        </Swiper>
      </Box>

    </Box>
  );
};

export default WebView;


const wrapper = theme => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '45px',
  '& .main-img': {
    width: '680px',
    maxHeight: 'unset',
    maxWidth: 'unset'
  },
  '& img': {
    width: '100%',
    maxWidth: '450px',
    maxHeight: '450px'
  },
  '& ul': {
    marginTop: '20px',
    listStyle: 'none',
    '& li': {
      fontSize: '20px'
    }
  },
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '100%',
      maxWidth: '300px',
      // maxHeight: '450px'
    },
    '& .main-img': {
      width: '480px',
      maxHeight: 'unset',
      maxWidth: 'unset'
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .main-img': {
      width: '380px',
      maxHeight: 'unset',
      maxWidth: 'unset'
    },
  },
})