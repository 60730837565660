import React from 'react';
import {Box} from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import bg from '../Components/images/background_img.webp'

const Layout = ({children}) => {
  return (
    <Box sx={wrapper}>
      <Header/>
      <Box sx={{minHeight: '100vh'}}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;

const wrapper = theme => ({
  backgroundImage: `url(${bg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
})