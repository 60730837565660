import React from 'react';
import {AppBar, Box, Button, Toolbar, Typography, useMediaQuery} from "@mui/material";
import {Link} from "react-router-dom";
// import Logo from "./images/logo";
import Logo from './images/huage-logo.png';
import MobileMenu from "./BurgerMenu/BurgerMenu";

const Header = () => {
  const matchXS = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <AppBar position="static" color={'warning'}>
      <Box className={'container'}>
        <Toolbar
          sx={headerSx}
        >
          <Link to={'/'}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <img style={{width: '40px'}} src={Logo} alt=""/>
              <Typography sx={{fontSize: '36px!important'}}>Хуагэ</Typography>
            </Box>
          </Link>
          {
            !matchXS ?
            <Box sx={linkWrapper}>
              <Link to={'/'}>Главная</Link>
              <Link to={'/catalog'}>Каталог</Link>
              <Link to={'/about'}>О компании</Link>
              {/*<Link>Контакты</Link>*/}
            </Box>
            :
            <MobileMenu />
          }
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Header;

const headerSx = theme => ({
  width: '100%',
  paddingX: '0!important',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const linkWrapper = theme => ({
  '& a': {
    color: 'white',
    textDecoration: 'none',
    margin: '0 10px',
    fontSize: '24px'
  }
})