import React from 'react';
import {Box, Typography} from "@mui/material";

const MobileView = ({product}) => {
  return (
    <Box sx={wrapper}
    >
      <Typography variant={"h2"}>{product.product}</Typography>

      <Box sx={{display: 'flex', gap: '20px'}}>
        <img src={product.mainImg} alt={product.product} className="main-img"/>

      </Box>
      {!!product.benefits.length && (
        <Box>
          <Typography variant={"h2"}>Преимущества:</Typography>
          <Box>
            {product.benefits.map((benefit, index) => (
              <Typography key={index}>{benefit}</Typography>
            ))}
          </Box>
        </Box>
      )}
      {!!product.properties.length &&
        <Box>
          <Typography variant={"h2"}>Свойства :</Typography>

          <Box>
            {product.properties.map((property, index) => (
              <Typography key={index}>{property}</Typography>
            ))}
          </Box>
        </Box>
      }

      {product.img2 && <img src={product.img2} alt="img2"/>}


      {product.img3 && <img src={product.img3} alt="img3"/>}

      {product.consequences_of_ignoring.length > 0 && (
        <Box>
          <Typography variant={"h2"}>Последствия игнорирования:</Typography>
          <Box>
            {product.consequences_of_ignoring.map((consequence, index) => (
              <Typography key={index}>{consequence}</Typography>
            ))}
          </Box>
        </Box>
      )}
      {product.img4 && <img src={product.img4} alt="img4"/>}

      {product.functions.length > 0 && (
        <Box>
          <Typography variant={"h2"}>Функции:</Typography>
          <Box>
            {product.functions.map((func, index) => (
              <Typography key={index}>{func}</Typography>
            ))}
          </Box>
        </Box>
      )}
      {product.img5 && <img src={product.img5} alt="img5"/>}

      {product.functional_ingredients.length > 0 && (
        <Box>
          <Typography variant={"h2"}>Функциональные ингредиенты:</Typography>
          {product.functional_ingredients.map((ingredient, index) => (
            <div key={index}>
              <Typography variant={"h3"}>{ingredient.name}</Typography>
              <Box>
                {ingredient.description.map((desc, i) => (
                  <Typography key={i}>{desc}</Typography>
                ))}
              </Box>
            </div>
          ))}
        </Box>
      )}
      {product.img6 && <img src={product.img6} alt="img6"/>}

      {/*<Box className="details">*/}

        {product.uses.length > 0 && (
          <Box>
            <Typography variant={"h2"}>Использование:</Typography>
            <Box>
              {product.uses.map((use, index) => (
                <Typography key={index}>{use}</Typography>
              ))}
            </Box>
          </Box>
        )}
        {product.img7 && <img src={product.img7} alt="img7"/>}

        {product.application_areas.length > 0 && (
          <Box>
            <Typography variant={"h2"}>Области применения:</Typography>
            <Box>
              {product.application_areas.map((area, index) => (
                <Typography key={index}>{area}</Typography>
              ))}
            </Box>
          </Box>
        )}

        {product.img8 && <img src={product.img8} alt="img8"/>}

        {product.functional_ingredients.length > 0 && (
          <Box>
            <Typography variant={"h2"}>Функциональные ингредиенты:</Typography>
            {product.functional_ingredients.map((ingredient, index) => (
              <React.Fragment key={index}>
                <Typography variant={"h3"}>{ingredient.name}</Typography>
                <Box>
                  {ingredient.description.map((desc, i) => (
                    <Typography key={i}>{desc}</Typography>
                  ))}
                </Box>
              </React.Fragment>
            ))}
          </Box>
        )}
        {product.img9 && <img src={product.img9} alt="img9"/>}
        <Box sx={{
          display: 'flex',
           flexDirection: 'column',
          gap: '10px'
        }}>
          <Typography variant={"h2"}>Презентация:</Typography>
          <Typography><strong>Бренд:</strong> {product.presentation.brand}</Typography>
          <Typography><strong>Название:</strong> {product.presentation.name}</Typography>
          <Typography><strong>Спецификация:</strong> {product.presentation.specification}</Typography>
          <Typography><strong>Область применения:</strong> {product.presentation.application_area}</Typography>
        </Box>
        {product.additional_info.targeted_action && (
          <Box>
            <Typography variant={"h2"}>Целенаправленное действие:</Typography>
            <Box>
              {product.additional_info.targeted_action.map((action, index) => (
                <Typography key={index}>{action}</Typography>
              ))}
            </Box>
          </Box>
        )}
        {product.img10 && <img src={product.img10} alt="img9"/>}

        {product.additional_info.application_method && (
          <Box>
            <Typography variant={"h2"}>Метод применения:</Typography>
            <p>{product.additional_info.application_method}</p>
          </Box>
        )}
        {product.img11 && <img src={product.img11} alt="img9"/>}

      {/*</Box>*/}
    </Box>
  );
};

export default MobileView;

const wrapper = theme => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '25px',
  '& img': {
    width: '100%',
    maxWidth: '450px'
  },
  '& p': {
    fontSize: '22px'
  },
  '& h2': {
    fontSize: '26px'
  },
  [theme.breakpoints.down(450)]: {
    '& p': {
      fontSize: '18px'
    },
    '& h2': {
      fontSize: '22px'
    },
  }
})